<!-- eslint-disable vue/no-template-shadow -->
<template>
  <material-card :color="color" full-header>
    <template #heading v-if="requiredSearch || isCrud">
      <v-row>
        <v-col cols="4" v-if="isCrud">
          <v-btn class="mx-2" color="cyan-darken-4" size="x-large" icon="mdi-magnify-plus-outline" elevation="12"
            @click="abrirForm()">
          </v-btn>
          <span style="font-size: x-large">{{ titleButton }}</span>
        </v-col>
        <v-col cols="6">
          <v-text-field v-if="requiredSearch" v-model="search" label="Buscar" single-line hide-details
            append-inner-icon="mdi-magnify" variant="outlined" class="mr-4 ml-2" />
        </v-col>
        <slot name="heading" />
      </v-row>
    </template>

    <template #heading v-else>
      <slot name="heading" />
    </template>
    <v-data-table density="compact" :headers="tableHeaders" :items="items" :loading="loading" :search="search"
      :items-per-page="10" loading-text="Cargando... Por favor espere" locale="es-ES" single-line hide-details
      class="mt-4">

      <template v-if="acctionsTable === 3" v-slot:[`item.estatus`]="{ item }">
        <v-chip :color="getColorPolizas(item.estatus)" variant="flat" dark>
          {{ item.estatus }}
        </v-chip>
      </template>

      <template v-if="acctionsTable === 10 || acctionsTable === 1" v-slot:[`item.Desc_Estatus`]="{ item }">
        <v-chip :color="acctionsTable == 1 ? item.Color :getColor(item.Id_Estatus)" :variant="acctionsTable == 1 ? 'flat':''" dark>
          {{ item.Desc_Estatus }}
        </v-chip>
      </template>

      <template v-if="acctionsTable === 10" v-slot:[`item.activo`]="{ item }">
        <v-chip :color="getRowClass(item.Atencion)" label flat>
          {{ item.activo }}
        </v-chip>
      </template>

      <template v-if="acctionsTable === 1" v-slot:[`item.Activo`]="{ item }">
        <v-switch v-model="item.Activo" hide-details color="success" @click="openEstatus(item)"></v-switch>
      </template>

      <template v-slot:[`item.Ruta`]="{ item }">
        <v-card class="my-2" elevation="2" rounded
          @click="imagenDialog(item.Ruta == '' ? '../img/sin_imagen_2.jpg' : $globalData.value.baseURL + item.Ruta)">
          <v-img :src="item.Ruta == '' ? '../img/sin_imagen_2.jpg' : $globalData.value.baseURL + item.Ruta" height="64" cover></v-img>
        </v-card>
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-item-group v-model="model" class="d-flex justify-sm-space-between px-1">
          <v-item v-for="(elemento, index) in item.acciones" :key="index">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn icon v-bind="props" :color="elemento.iconColor" @click="elemento.accion(item)" size="x-small"
                  class="mx-1">
                  <v-icon size="x-large">
                    {{ elemento.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ elemento.tooltip }}</span>
            </v-tooltip>
          </v-item>
        </v-item-group>
      </template>
    </v-data-table>

    <ImagenDialog :open-d="dialogImg" :imagen="urlImg" @close="dialogImg = false"></ImagenDialog>
  </material-card>
</template>

<script>
import ImagenDialog from './ImagenDialog'
export default {
  name: "TableComponent",
  components: {
    ImagenDialog,},
  props: {
    color: {
      type: String,
      required: false,
      default: "success"
    },
    titleButton: {
      type: String,
      required: false,
      default: ""
    },
    headers: {
      type: Array,
      required: true
    },
    itemsTable: {
      type: Array,
      required: true
    },
    acctionsTable: {
      type: Number,
      required: false,
      default: 1
    },
    requiredSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    externalSearch: {
      type: String,
      required: false,
      default: ""
    },
    isCrud: {
      type: Boolean,
      required: true,
      default: false
    },
    loadingTable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      dialogImg: false,
      urlImg:'',
      tableHeaders: [],
      loading: false,
      search: "",
      item: [],
      objItem: null,
      acciones: [],
      acciones_b: [
        {
          accion: this.openEdit,
          icon: "mdi-pencil",
          iconColor: "blue",
          tooltip: "Editar"
        }
      ],
      acciones_d: [
        {
          accion: this.download,
          icon: "mdi-tray-arrow-down",
          iconColor: "blue",
          tooltip: "Descargar documento"
        }
      ],
      acciones_c: [
        {
          accion: this.openEdit,
          icon: "mdi-clipboard-text-search-outline",
          iconColor: "blue",
          tooltip: "Actualizar"
        }
      ],
      acciones_p: [
        {
          accion: this.openEdit,
          icon: "mdi-pencil",
          iconColor: "blue",
          tooltip: "Actualizar"
        }
      ],
      acciones_s: [
        {
          accion: this.openEdit,
          icon: "mdi-pencil",
          iconColor: "blue",
          tooltip: "Actualizar"
        },
        {
          accion: this.openSeguimieto,
          icon: "mdi-comment-arrow-right-outline",
          iconColor: "deep-orange",
          tooltip: "Seguimiento"
        },
        {
          accion: this.openEstatus,
          icon: "mdi-swap-horizontal-bold",
          iconColor: "green",
          tooltip: "Comparar"
        }
      ]
    };
  },
  watch: {
    itemsTable: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.mapItemsData();
        }
      }
    },
    headers: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.tableHeaders = newValue;
      }
    },
    loadingTable: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.loading = newValue;
      }
    }
  },
  mounted() { },
  methods: {
    getRowClass(toBe) {
      let color = ''

      switch (toBe) {
        case 1:
          color = 'warning'
          break
        case 2:
          color = 'green'
          break
        case 3:
          color = 'red'
          break
        default:
          color = ''
          break
      }

      return color;
    },
    mapItemsData() {
      var self = this;
      try {
        var items = self.itemsTable;
        switch (this.acctionsTable) {
          case 0:
            this.acciones = this.acciones_c;

            this.acciones[0].tooltip = "Cancelar folio";

            self.items = items.map((e) => {
              e.acciones = this.acciones;
              return e;
            });
            break;
          case 1:
            this.acciones = this.acciones_b;

            this.acciones[0].tooltip = "Modificar";

            self.items = items.map((e) => {
              e.Activo = e.Activo == 1 ? true : false;
              e.acciones = this.acciones;
              return e;
            });
            break;
          case 2:
            this.acciones = this.acciones_d;

            this.acciones[0].tooltip = "Descargar documento";

            self.items = items.map((e) => {
              e.acciones = this.acciones;
              return e;
            });
            break;
          case 3:
            this.acciones = this.acciones_p;

            this.acciones[0].tooltip = "Actualizar";
            // this.acciones[1].tooltip = "Comparar";

            this.accioness = this.acciones_s;

            this.accioness[0].tooltip = "Actualizar";
            this.accioness[1].tooltip = "Seguimiento";
            this.accioness[2].tooltip = "Comparar";

            self.items = items.map((e) => {
              if (e.estatus == 'Sin ejercicios') {
                e.acciones = this.acciones;
              } else {
                e.acciones = this.accioness;
              }

              return e;
            });
            break;
          case 10:
            this.acciones = this.acciones_c;

            this.acciones[0].tooltip = "Actualizar";

            self.items = items.map((e) => {
              e.acciones = this.acciones;
              return e;
            });

            break;
          default:
            self.items = items;
            break;
        }
      } catch (e) {
        self.items = [];
        console.log(e);
      }
    },
    abrirForm() {
      this.$emit("open-form");
    },
    openEdit(item) {
      this.$emit("item-edit", item);
    },
    download(item) {
      this.$emit("item-download", {
        id: item.docId,
        format: item.docTipoDocumento,
        folio: item.stdIdFolioSolicitud
      });
    },
    openChangePass(item) {
      this.$emit("change-pass", item);
    },
    openSeguimieto(item) {
      this.$emit("seguimiento", item);
    },
    openEstatus(item) {
      // console.log("Entra table")
      this.$emit("item-estatus", item);
    },
    getColor(idEstatus) {
      switch (idEstatus) {
        case 0:
          return "";
        case 1:
          return "orange";
        case 2:
          return "gray";
        case 3:
          return "green";
        case 4:
          return "red";
        case 5:
          return "teal";
        default:
          return "";
      }
    },
    getColorPolizas(item) {
      switch (item) {
        case 'Inicial':
          return "grey"
        case 'Vencida':
          return "red-accent-4"
        case 'Asignado':
          return "indigo"
        case 'Adjudicado':
          return "pink"
        case 'Comprometida':
          return "orange"
        case 'Devengada':
          return "green-2"
        case 'Vigente / Pagada':
          return "green-darken-4"
        default:
          return "";
      }
    },
    imagenDialog(url) {
      this.urlImg = url
      this.dialogImg = true
    }
  }
};
</script>

<style>
.v-data-table::v-deep th {
  font-size: 14px !important;
}

.v-data-table::v-deep td {
  font-size: 12px !important;
}
</style>
