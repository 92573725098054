<template>
    <div class="pa-0 text-center">
        <v-dialog v-model="dialog" width="auto">
            <v-card>
                <v-sheet color="#000000">
                    <v-card-item class="pa-0">
                      <template v-slot:append>
                        <v-btn
                          icon="mdi-close-box-outline"
                          variant="text"
                          color="red"
                          @click="$emit('close')"
                        ></v-btn>
                      </template>
                    </v-card-item>
                  </v-sheet>
                <img :src="url" alt="">
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions> -->
            </v-card>
            
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'ImagenDialog',
    props: {
        openD: {
            type: Boolean,
            default: false
        },
        imagen: {
            type: String,
            default: ''
        },
    },
    watch: {
        openD: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.dialog = newValue
            }
        },
        imagen: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if(newValue !== ''){
                    this.url = newValue
                }else{
                    this.url = '../img/sin_imagen_2.jpg'
                }
                
            }
        },
    },
        data() {
            return {
                dialog: false,
                url:'../img/sin_imagen_2.jpg'
            }
        },
    }
</script>

<style lang="scss" scoped></style>